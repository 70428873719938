import { navigate } from "gatsby"
import React from "react"
import { Layout } from "../../components/Layout"
import { SingleAppointment } from "../../components/SingleAppointment"
import { AppointmentsSection } from "../../style/appointments"

function appointment({ location = {} }) {
  if (location.state === null || undefined) {
    // console.log(location.state)
    navigate("/")
  }

  // console.log("appointment from navigation", location.state)

  return (
    <div>
      <Layout>
        <AppointmentsSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                navigate("/appointments")
                // ;((location.state.data.isPrimary === true ||
                //   location.state.data.isAgent === true) &&
                //   typeof history !== "undefined" &&
                //   history.go(-1)) ||
                //   (typeof history !== "undefined" && history.go(-2))
              }}
              style={{
                cursor: "pointer",
                // textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Appointments
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              /{/* &#8827; */}
            </p>
            {/* {typeof location.state !== `undefined` &&
              location.state.data.isPrimary === undefined && (
                <>
                  <p
                    onClick={() => {
                      typeof history !== "undefined" && history.go(-1)
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "0.7rem",
                      marginBottom: "1rem",
                    }}
                  >
                    
                  </p>
                  <p
                    style={{
                      // cursor: "pointer",
                      fontSize: "0.9rem",
                      marginBottom: ".9rem",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    {" "}
                    &#8827;
                  </p>
                </>
              )} */}
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Appointment ID:{" "}
              {location.state ? location.state.data.orderSeqId : " "}
            </p>
          </div>
          <h1>
            appointment ID -{" "}
            <span style={{ textTransform: "lowercase" }}>
              {location.state && location.state.data.orderSeqId}
            </span>
          </h1>
          <SingleAppointment
            appointmentData={location.state && location.state.data}
          />
        </AppointmentsSection>
      </Layout>
    </div>
  )
}

export default appointment
