import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Form, SelectStyles } from "../style/globalStyles"
import { navigate } from "gatsby"
import Select from "react-select"
import DatePicker from "react-datepicker"
import Switch from "react-switch"
import Moment from "moment"

import { updateAppointment } from "../state"
export const SingleAppointment = ({ appointmentData = {} }) => {
  const [state, setState] = useState({ appointmentData })
  const [enable, setEnable] = useState(false)
  const [orderStatusUpdate, setOrderStatusUpdate] = useState({
    orderSeqId: appointmentData.orderSeqId,
    orderStatus: appointmentData.orderStatus,
  })
  const [orderStatusEnable, setOrderStatusEnable] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (appointmentData) {
      setState(appointmentData)
    }
    // console.log("useEffect state", state)
  }, [appointmentData])

  const orderStatus = [
    { label: "Booked", value: 100080001 },
    { label: "Confirmed", value: 100080002 },
    { label: "Completed", value: 100080003 },
    { label: "Cancelled", value: 100080004 },
    { label: "On Going", value: 100080005 },
  ]
  const orderStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case 100080001:
        return "teal"
      case 100080002:
        return "green"
      case 100080003:
        return "steelblue"
      case 100080004:
        return "red"
      case 100080005:
        return "darkorange"
      default:
        return "gray"
    }
  }

  const [orderDefaultStatus, setOrderDefaultStatus] = useState(
    orderStatus.filter((status) => {
      if (status.value === appointmentData.orderStatus) {
        return status.label
      }
    })[0]
  )

  // console.log(orderDefaultStatus)
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)
  }
  const orderStatusHandle = (e) => {
    setEnable(true)
    // handleChange(e)
    setOrderDefaultStatus(e.label)
    setOrderStatusEnable(true)
    setOrderStatusUpdate({
      ...orderStatusUpdate,
      orderStatus: Number(e.target.value),
    })
    // console.log("order Status change", e, orderStatusUpdate)
  }
  const dateChangeHandle = (e) => {
    setEnable(true)
    // console.log("order date change", e)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (enable && orderStatusEnable) {
      // console.log(
      //   "can diapatch for status update of appointment",
      //   orderStatusUpdate,
      //   "statusUpdate"
      // )
      setEnable(false)
      dispatch(
        updateAppointment(
          { ...appointmentData, ...orderStatusUpdate },
          "statusUpdate"
        )
      )
    }
    return
  }
  // var date = appointmentData.appointmentDate.split(" ")[0]

  function format(input) {
    var date = new Date(input)
    return [
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
      date.getFullYear(),
    ].join("/")
  }
  // console.log(String(format(date)))

  return (
    <Form onSubmit={handleSubmit} style={{}}>
      <div style={{ paddingRight: "1rem" }}>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            borderBottom: "1px solid lightgray",
          }}
        >
          <label htmlFor="appointmentId">
            Appointment ID
            <p>{state.orderSeqId ? state.orderSeqId : "NA"}</p>
          </label>
          <label htmlFor="appointmentDate">
            Appointment Date
            <p>
              {state.appointmentDate
                ? Moment(state.appointmentDate).format("DD MMM YYYY")
                : "NA"}
            </p>
            <label></label>
          </label>
          <label htmlFor="slotDesc">
            Appointment Slot (24 hour)
            <p>{state.slotDesc ? state.slotDesc : "NA"}</p>
          </label>
          <label htmlFor="appointmentStatus">
            Appointment Status
            <label>
              <select
                defaultValue={appointmentData.orderStatus}
                options={orderStatus}
                onChange={orderStatusHandle}
                style={{
                  border:
                    orderStatusColor(orderStatusUpdate.orderStatus) +
                    " 2px solid",
                }}
              >
                {orderStatus.map((status, index) => (
                  <option value={status.value} id={index} key={index}>
                    {status.label}
                  </option>
                ))}
              </select>
            </label>
          </label>
          <label htmlFor="appointmentReason">
            Appointment Reason
            <p>
              {state.appointmentReasonLookupMap
                ? state.appointmentReasonLookupMap
                    .map((lookUp) => {
                      return lookUp.lookupDescription
                    })
                    .join(", ")
                : "NA"}
            </p>
          </label>
          <label htmlFor="consultationFee">
            Consultation Fee
            <p>{state.consultationFee ? state.consultationFee : "NA"}</p>
          </label>
        </div>
        <div
          style={{
            marginBottom: "2rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            borderBottom: "1px solid lightgray",
          }}
        >
          <label htmlFor="doctor">
            Doctor
            <p>
              {state.doctorId
                ? `${state.doctorFirstName} ${state.doctorLastName}`
                : state.professionalId
                ? `${state.professionalFirstName} ${state.professionalLastName}`
              : "NA"}
            </p>
          </label>

          <label htmlFor="doctorSpecialityDesc">
            Doctor Speciality
            <p>
              {state.doctorSpecialityDesc ? state.doctorSpecialityDesc : state.professionalSpecialityDesc ? state.professionalSpecialityDesc : "NA"}
            </p>
          </label>

          <label htmlFor="doctorMobileNo">
            Doctor Mobile No.
            <p>{state.doctorMobileNo ? state.doctorMobileNo : state.professionalMobileNo ? state.professionalMobileNo : "NA"}</p>
          </label>
          <label htmlFor="patient">
            Patient
            <p>
              {state.patientId
                ? `${state.patientFirstName} ${state.patientLastName}`
                : "NA"}
            </p>
          </label>
          <label htmlFor="patientDob">
            Patient DOB
            <p>{state.patientDob ? state.patientDob.split(" ")[0] : "NA"}</p>
          </label>
          <label htmlFor="patientMobileNo">
            Patient Mobile No.
            <p>{state.patientMobileNo ? state.patientMobileNo : "NA"}</p>
          </label>
        </div>
        <div
          style={{
            marginBottom: "2rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <label htmlFor="clinicName">
            Clinic Name
            <p>
              {" "}
              {appointmentData.clinic
                ? appointmentData.clinic.clinicName
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicPhoneNo">
            Phone No
            <p>
              {appointmentData.clinic
                ? appointmentData.clinic.clinicPhoneNo
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicEmail">
            Email
            <p>
              {appointmentData.clinic
                ? appointmentData.clinic.clinicEmail
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicAddress">
            Address
            <p>
              {" "}
              {appointmentData.clinic
                ? appointmentData.clinic.clinicAddress
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicCity">
            City
            <p>
              {" "}
              {appointmentData.clinic
                ? appointmentData.clinic.clinicCity
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicState">
            State
            <p>
              {appointmentData.clinic
                ? appointmentData.clinic.clinicState
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicCountry">
            Country
            <p>
              {appointmentData.clinic
                ? appointmentData.clinic.clinicCountry
                : "NA"}
            </p>
          </label>
          <label htmlFor="clinicPincode">
            Pincode
            <p>
              {appointmentData.clinic
                ? appointmentData.clinic.clinicPincode
                : "NA"}
            </p>
          </label>

          <p />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          height: "fit-content",
        }}
      >
        <label htmlFor="imcRegistrationNo">
          IMC Registration
          <p>{state.imcRegistrationNo ? state.imcRegistrationNo : "NA"}</p>
        </label>
        <label htmlFor="created">
          Appointment Created
          <p>
            {appointmentData.created &&
              Moment(appointmentData.created).format("DD MMM YYYY")}
          </p>
        </label>
        <br />

        <br />

        <button
          type="submit"
          aria-disabled={enable ? true : false}
          style={{
            borderRadius: "5px",
            backgroundColor: enable ? "green" : "lightgray",
            cursor: enable ? "pointer" : "default",
          }}
        >
          Save Changes
        </button>
      </div>
    </Form>
  )
}
